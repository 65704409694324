$main-color-light: #FCECC9;
$main-color-dark: #293241; //#474554;
$secondary-color-light: white;
$secondary-color-dark: black;
$pop-color: #5465FF;
$sub-color: #B5D5FE;
.output-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    min-height: 100vh;
    // border: 1px dotted black; // Production
    .output-summary {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        // border: 1px dotted black;
        .summary-title {
            position: absolute;
            top: 42vh;
            font-size: 1.5rem;
            text-align: center;
            width: 100%;
        }
        .summary-content-container {
            // border: 1px dotted black;
            position: relative;
            display: flex;
            width: 100%;
            margin-top: 64px;
            .summary-content-images {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 60%;
                // border: 1px solid white;
                .user-image {
                    max-width: 80%;
                    max-height: 55vh;
                    border-radius: 0.5rem;
                    box-shadow: -6px -3px 2px 1px #29324190;
                    position: relative;
                    z-index: 10;
                }
                .dm-user-image {
                    box-shadow: -6px 4px 4px 1px #fcecc990;
                }
                .svg-disk {
                    width: 100%;
                    position: relative; 
                    z-index: 5;
                }
                .summary-graph {
                    position: relative;
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
            }
            .summary-content-main {
                position: relative;
                z-index: 10;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                justify-content: center;
                width: 40%;
                // border: 1px dotted white;
                .summary-content-texts{
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    margin-top: 1rem;
                    width: 100%;
                    border: 1px solid $main-color-dark;
                    padding-bottom: 1rem;
                    .summary-main-title {
                        position: relative;
                        font-size: 1.125rem;
                        width: 100%;
                        text-align: center;
                    }
                    .summary-detail {
                        position: relative;
                        font-size: 1rem;
                        margin: 0.5rem;
                        width: 100%;
                        z-index: 10;
                        .header {
                            font-weight: bold;
                            // color: $pop-color;
                        }
                        .header-dm {
                            font-weight:lighter;
                            color: $sub-color;
                        }
                    }
                    .sub {
                        width: 80%;
                    }
                    .score-title {
                        margin-bottom: 0.75rem;
                    }
                    .score {
                        text-align: center;
                        margin: 0.25rem;
                    }
                    .summary-break {
                        width: 60%;
                        height: 0%;
                        border: none;
                        border-top: 2.5px solid $sub-color;
                        opacity: 70%;
                        margin-top: 1rem;
                    }
                    .summary-break-dm {
                        border-top: 2px solid $pop-color;
                    }
                    .bub {
                        position: absolute;
                        z-index: 5;
                    }
                    .main-bub {
                        right: -5rem;
                        top: -5rem;
                    }
                    .sec-bub {
                        left: -4rem;
                        bottom: -2rem;
                    }
                }
                .summary-content-texts-dm {
                    border: 1px solid $main-color-light;
                }
                .summary-content-graph {
                    width: 100%;
                    .svg-container {
                        width: 50% !important;
                    }
                }
            }
        }
    }
    .output-recs {
        margin-top: 5rem;
        margin-bottom: 8rem;
        // border: 1px dotted black;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        overflow: visible;
        .output-recs-title {
            width: 100%;
            font-size: 1.25rem;
            margin-left: -2rem;
            margin-bottom: 0rem;
        }

    }

}


/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1023px){
    .output-container {
        .output-summary {
            .summary-title {
                font-size: 1.25rem;
            }
            .summary-content-container {
                flex-wrap: wrap;
                .summary-content-images {
                    width: 100%;
                    .user-image {
                        max-width: 70%;
                        max-height: 50vh;
                        border-radius: 2.4rem;
                        box-shadow: -3px -1.5px 1px 1px #29324190;
                    }
                    .dm-user-image {
                        box-shadow: -3px 1.5px 1px 1px #fcecc990;
                    }
                    .svg-disk {
                        width: 50%;
                        margin-top: -.75rem;
                    }
                }
                .summary-content-main {
                    width: 100%;
                    .summary-content-texts{
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                        margin-top: 1rem;
                        width: 100%;
                        border: 1px solid $main-color-dark;
                        padding-bottom: 1rem;
                        .summary-main-title {
                            font-size: 1rem;
                        }
                        .summary-detail {
                            font-size: 0.75rem;
                        }
                        .bub {
                            position: absolute;
                            z-index: 0;
                            opacity: 0.2;
                        }
                        .main-bub {
                            right: -5rem;
                            top: -5rem;
                        }
                        .sec-bub {
                            left: -4rem;
                            bottom: -3rem;
                        }
                    }
                    .summary-content-texts-dm {
                        border: 1px solid $main-color-light;
                    }
                }
            }
        }
        .output-recs {
            margin-top: 6rem;
            .output-recs-title {
                font-size: 1rem;
                margin-left: 0rem;
            }
    
        }
    
    }
}