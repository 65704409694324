$main-color-light: #FCECC9;
$main-color-dark: #293241; //#474554;
$secondary-color-light: white;
$secondary-color-dark: black;
$pop-color: #5465FF;
$sub-color: #B5D5FE;
body {
    margin: 0;
    // #root {
    //     width: 100vw;
    //     height: 100vh;
    // }
}


.app {
    // width: 100%;
    height: 100%;
    background-color: $main-color-light;
    color: $main-color-dark;
    font-family: 'Plus Jakarta Sans', 'Vazirmatn', sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .main-container {
        height: 100vh;
        width: 70vw;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .col {
            width: 48%;
        }
        .intro{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            position: relative;
            .intro-bubbles {
                position: absolute;
                z-index: 2;
                width: 100%;
                left: -50px;
                top: -50px;
                .intro-title-bub {
                    position: absolute;
                    left: 110px;
                    top: 15px;
                    opacity: 40%;
                }
                .intro-title-bub-large {
                    position: absolute;
                    left: 0;
                    top: -35px;
                    opacity: 40%;
                }
            }
            .intro-title{
                position: relative;
                z-index: 50;
                font-size: 1.25rem;
                width: 100%;
                .intro-title-text {
                    position: relative;
                    z-index: 10;
                }
                .highlightBox { 
                    content: "";
                    position: absolute;
                    height: 0.5rem;
                    width: 5rem;
                    margin-top: 0.75rem;
                    margin-left: -5rem;
                    background-image: linear-gradient(-120deg, #e0afa01d, #b5d5fe97 95%, #b5d5fe89);
                    border-radius: 1rem 0 1rem 0;
                    padding: 3px 6px;
                    z-index: 5;
                  }
            }
            .intro-desc {
                font-size: 1rem;
                width: 90%;
                padding: 24px;
                font-weight: lighter;
                font-family: 'Vazirmatn', sans-serif;
            }
        }
        .form {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border: 0.75px solid $main-color-dark;
            box-shadow: 12px 12px 2px 1px #b5d5fe81;
            .form-desc-title { 
               font-size: 1.25rem;
               width: 100%;
               text-align: center;
            }
            .form-desc-text {
                font-size: 0.825rem;
                font-weight: lighter;
                width: 80%;
                text-align: center;
            }
            .hr-break {
                width: 55%;
                border-top: 1px solid $pop-color;
                opacity: 50%;
                margin-bottom: 1.5rem;
            }
            .form-sheet {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                .form-dropdown-label {
                    text-align: center;
                    font-family: 'Vazirmatn', sans-serif;
                    font-size: 1rem;
                }
                .form-dropdown-menu {
                    background-color: $main-color-light;
                    color: $main-color-dark;
                    text-align: center;
                    width: 55%;
                }
                .form-dropdown-menu.dark {
                    background-color: $main-color-dark;
                    color: $main-color-light;
                }
                .form-upload {
                    margin-top: 1em;
                    justify-content: center;
                    font-family: 'Vazirmatn', sans-serif;
                }
                .form-break { 
                    width: 100%;
                    height: 0; 
                    margin: 0; 
                    border: 0;
                }
                .form-submit {
                    margin-top: 1.5em;
                    width: 50%;
                    height: 1.5rem;
                    background-color: $sub-color;
                    opacity: 0.6;
                    font-family: 'Lora', sans-serif;
                    border: none;
                    border-radius:1em;
                    box-sizing: border-box;
                    text-decoration:none;
                    text-align: center;
                    margin-bottom: 2rem;
                }
            }
            .form-warning {
                font-size: 0.75rem;
                opacity: 0.5; 
                margin-top: -1.25rem;
                padding: 0.75rem;
                text-align: center;
            }
        }
        .dm-form {
            border: 0.75px solid $main-color-light;
        }
    }
    .loading-container {
        height: 100vh;
        display: flex;
        align-items: center;
        .loading-subtitle { 
            margin-left: 1.5rem;
        }
        .svg-circle {
            overflow: visible;
        }
    }

    .dm-button{
        padding: 8px;
        display: flex;
        align-items: center;
        margin: 0 4px 0 4px;
        position: absolute;
        bottom: 20px;
        left: 20px;
        opacity: 0.7;
        z-index: 1100;
        // Button State Image
        .dm-image {
            // height: calc(0.45 * #{$toggle-height});
            height: calc(0.45 * 26px);
            width: auto;
            margin-left: calc(0.25 * 26px);
            margin-top: calc(0.1 * 26px);
            display: flex;
            align-items: center;
        }
        a.icon-wrapper {
            height: 32px; // 48px total height
            padding: 8px;
            z-index: 1105; // body->navbar->toggle->contacts
            position: absolute;
            left: calc(50vw - 32px);
            opacity: 0.2;
            .contact-icon {
                height: 100%;
                width: auto;
                z-index: 140;
            }
        }
    }
} 

.app.dark {
    background-color: $main-color-dark;
    color: $main-color-light;
}

/* Media Query for Tablets Ipads portrait mode */
@media (max-width: 1023px){
    .app {
        overflow-y: visible;
        width: 100vw;
        .loading-container{
            padding: 18px;
            font-size: 0.75rem;
        }
        .main-container { 
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            // height: 100vh;
            width: 82vw;
            .col {
                width: 100%;
            }
            .intro{
                justify-content: center;
                margin-bottom: 12px;
                .intro-bubbles {
                    left: 0.125rem;
                }
                .intro-title {
                    display: flex;
                    justify-content: center;
                    .highlightBox {
                        margin-left: 5rem;
                    }
                }
                .intro-desc {
                    margin-top: 24px;
                    padding: 0;
                }
            }
            .form {
                .form-sheet {
                    .form-upload {
                        width: 60%;
                    }
                }
            }
        }
        .dm-button{
            bottom: 12px;
            left: 8px;
            opacity: 0.5;
        }
    }
    // .loading-app {
    //     height: 100vh;
    // }
}
// @media (max-width: 767px) {
//     .card-container {
//         width: 90%;
//     }
// }